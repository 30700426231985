<script setup lang="ts">
import { useSound } from '@vueuse/sound'
import click from '~/assets/sounds/click.mp3'
const { play } = useSound(click)
export interface TextButtonProperties {
  label?: string,
  size?: 'small'|'normal'|'large',
  icon?: 'arrow'|'cancel',
  disabled?: boolean,
  marginBottom?: boolean,
}
const props = withDefaults(defineProps<TextButtonProperties>(), {
  size: 'normal'
})
const emit = defineEmits(['click'])

const onClick = (event: MouseEvent) => {
  if (!props.disabled || event.altKey) {
    play()
    emit('click', event)
  }
}

</script>

<template>
  <div class="text-button"
       :class="{
          'text-button--small': size === 'small',
          'text-button--normal': size === 'normal',
          'text-button--large': size === 'large',
          'text-button--margin-bottom': marginBottom === true,
        }"
  >
    <div role="button" class="text-button__main" :class="{disabled}" @click="onClick($event)">
      <div class="text-button__main-inner"></div>
      <div class="text-button__main-text">{{ label }}</div>
      <div class="text-button__main-icon" :class="{cancel: icon === 'cancel'}"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/main.css";

.text-button {
  //display: inline-block;
  height: 66px;
  /* allow 'arrow' part outside of the button */
  overflow: visible;
  pointer-events: auto;
}

.text-button--large {
  min-width: 313px;
}

.text-button--normal {
  min-width: 200px;
}

.text-button--small {
  max-width: 168px;
}

.text-button__main {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: calc(105px / 2);
  background: linear-gradient(61deg, #D2D2D2 0%, white 100%);
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.20);
  text-transform: uppercase;
  border: none;
  font-size: 21px;
  font-family: "Arial", sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  color: #000D89;
  cursor: pointer;
  filter: drop-shadow(6px 6px 18px rgba(0, 0, 0, 0.1));

  .text-button__main-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    border-radius: calc(105px / 2);
    opacity: 35%;
    filter: drop-shadow(12px 12px 0 rgba(145, 1, 16, 0.9));
    /*mix-blend-mode: multiply;*/
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;

    .text-button__main-inner {
      filter:
          drop-shadow(0 0 0 #910110C0)
          drop-shadow(0 0 0 #000000);
      /*mix-blend-mode: multiply;*/
    }
  }

  &:active:hover {
    filter: brightness(120%);

    .text-button__main-inner {
      filter:
          drop-shadow(4px 4px 0 #910110C0)
          drop-shadow(6px 6px 6px #000000);
      /*mix-blend-mode: multiply;*/
    }
  }
}

.text-button__main-text {
  position: relative;
  padding: 0 40px 0 20px;
}

.text-button__main-icon {
  position: absolute;
  top: -3px;
  right: -36px;
  width: 93px;
  height: 116px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("@/assets/images/ui/buttons/btn_next.png");
  /*background-size: 100%;*/
  mix-blend-mode: normal;
  /*filter: drop-shadow(6px 6px 18px #ffffff);*/
}



.text-button__main-icon.cancel {
  background-image: url("@/assets/images/ui/buttons/btn_cancel.png");
}

.text-button--margin-bottom {
  margin-bottom: 45px;
}
</style>
